<template>
	<div class="XzAuditDetail">
		<w-navTab titleText="审核进度"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="AuditDetail-content">
			<div class="content-body">
				<div :class="['content-item',{'item-active': item.audit_status == 1}]" v-for="(item,index) in deleteList">
					<div class="content-item-title">
						<div class="item-title-icon">
							<van-icon name="clock" size="20" color="#DDDDDD" v-if="item.audit_status != 1 && item.audit_status != 2" />
							<van-icon name="checked" size="20" color="#3377FF" v-if="item.audit_status == 1"/>
							<van-icon name="clear" size="20" color="#FF6969"  v-if="item.audit_status == 2"/>
						</div>
						<div :class="['item-title-text',{'title-acitve': item.type < 3}]">{{item.title}}</div>
					</div>
					<template v-if="index == 0">
					<div class="content-item-text">{{item.create_time}}</div>
					<div class="content-item-text">{{item.user_name}}</div>
					<!-- <div class="content-item-text">{{item.department_txt}}</div> -->
					<!-- <div class="content-item-text">推荐人：{{item.referrer}} {{item.referrer_tel}}</div> -->
					</template>
					
					<template v-if="item.progress > 1 && item.type == 1">
						<div class="content-item-text">{{item.create_time}}</div>
						
						<template v-if="item.progress == 3 && item.type == 1">
							<div class="content-item-text text-active" v-if="item.audit_status == 1">政审合格</div>
							<div class="content-item-text text-active2" v-else>政审不合格</div>
						</template>
						<div class="content-item-text "><span>回复内容：</span>{{item.note}}</div>
					</template>
					
					<!-- 政审上传照片功能 -->
					<template v-if="index == 2">
						<template v-if="item.progress == 3 && item.audit_status == 1">
							<div class="uploader-title" v-if="item.audit_data != ''">政审照片</div>
							<div class="uploader">
								<div class="uploader-item" v-for="(vo, index) in item.audit_data" @click.stop="onPreview(item.audit_data, index)">
									<img class="uploader-image" :src="vo" />
								</div>
							</div>
						</template>
						<template v-if="userInfo.duty_level == 3 && applyType == 1">
							<div class="uploader-title">政审照片</div>
							<div class="uploader">
								<div class="uploader-item" v-for="(vo, index) in picture">
									<img class="uploader-close" src="@/assets/img/uploader_icon_close.png"
										@click="deletePicture(index)" />
									<img class="uploader-image" :src="vo.url" />
								</div>
								<div class="uploader-item uploader-item-select">
									<van-uploader v-show="isShowUploader" :after-read="onSelect" upload-icon="plus"></van-uploader>
								</div>
							</div>
						</template>
					</template>
				</div>
			</div>
			
			<div class="footer" v-if="applyType == 1">
				<div class="footer-button-refuse" @click="onClickShow(2)">拒绝</div>
				<div class="footer-button-pass" @click="onClickShow(1)">通过</div>
			</div>
		</div>
		<van-popup round v-model="show">
			<div class="popup">
				<div class="popup-content">
					<div class="popup-content-title">{{type == 2? '拒绝通过':'审核通过'}}</div>
					<div class="popup-content-text">{{type == 2? '填写不通过原因（可留空）':'填写通过原因（可留空）'}}</div> 
					<div class="popup-content-textarea"> 
						<textarea v-model="content" placeholder="请输入内容"></textarea>
					</div>
				</div>
				<div class="popup-footer">
					<div class="popup-footer-left" @click="onCancel">取消</div>
					<div class="popup-footer-right" @click="onSubmit()">提交</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import User from '@/api/user';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';
	import Event from '@/store/event';
	import UploadApi from '@/api/upload';
	
	export default {
		name: 'XzAuditDetail',
		data() {
			return {
				loadingPage: true,
				show: false,
				applyId: '',
				deleteList: [],
				type: 1,
				content: '',
				applyType: '',
				picture: [],
				userInfo: '',
				isShowUploader: true
			}
		},
		watch: {
			picture(newVal, oldVal) {
				this.isShowUploader = newVal.length < 3;
			}
		},
		created() {
			this.applyId = this.$route.params.id
			this.applyType = this.$route.params.type
			this.getAuditProgress()
			this.getUserInfo()
		},
		methods: {
			getAuditProgress() {
				let params = {
					apply_id: this.applyId
				}
				User.xzAuditProgress(params)
					.then(result => {
						
						// result.data.forEach((item,index)=>{
						// 	if(index == 1){
						// 		item.audit_status = 1
						// 		item.note = "111"
						// 		item.title = "县分会会长"
						// 		item.type = 1
						// 	}
						// })
						
						this.deleteList = result.data
						this.loadingPage = false
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
			},
			getUserInfo() {
				User.getUserInfo().then(result => {
					this.userInfo = result.data;
				});
			},
			// 预览图片
			onPreview(vo, index) {
				VantVendor.ImagePreview({
					images: vo,
					startPosition: index,
				});
			},
			onClickShow(type){
				this.type = type
				if(this.userInfo.duty_level == 3 && this.picture == ''){
					if(this.type == 1){
						return VantVendor.Toast('请上传政审照片')
					}
				}
				this.show = true
			},
			onCancel(){
				this.show = false
				this.content = ''
			},
			onSelect(selected) {
				VantVendor.Toast.loading('上传中');
				UploadApi.image(selected.file)
					.then(result => {
						this.picture.push({
							url: result.data.fileUrl,
							path: result.data.filePath
						});
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
					.finally(() => {
						VantVendor.Toast.clear();
					});
			},
			deletePicture(index) {
				this.picture.splice(index, 1);
			},
			onSubmit(){
				
				let list = []
				
				if(this.picture != ''){
					this.picture.forEach(item=>{
						list.push(item.path)
					})
				}
				
				let params = {
					apply_id: this.applyId,
					audit_status: this.type,
					note: this.content,
					audit_data: list.join(',')
				}
				
				User.xzAuditIndex(params)
					.then(result => {
						Event.$emit('XzAuditItem', this.applyId);
						if(this.type == 1){
							this.deleteList.forEach((item,index)=>{
								if(item.type == 2 && index == 1){
									VantVendor.Toast.success('审核通过，等待县级分会会长审核！')
								}else if(item.type == 2 && index == 2){
									VantVendor.Toast.success('审核通过，等待市级会长审核！')
								}else{
									VantVendor.Toast.success('审核通过')
								}
								
							})
						}else{
							VantVendor.Toast.success('拒绝成功')
						}
						setTimeout(()=>{
							this.$router.back();
						},500)
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					})
			}
		},
		components: {
			VantVendor,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.XzAuditDetail {
		width: 100%;
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 70px;
		box-sizing: border-box;
		

		.AuditDetail-content {
			padding: 16px 16px 16px 21px;
			box-sizing: border-box;
			height: 100%;
			
			.content-body {
				height: 100%;
				
				.content-item {
					padding-bottom: 24px;
					height: 100%;
					border-left: 1px solid #DDD;

					&.content-item:last-child{
						border: 0;
					}
	
					&.item-active {
						border-left: 1px solid #37F;
					}

					.content-item-title {
						margin-bottom: 14px;
						display: flex;
						align-items: center;
						margin-left: -10px;
						
						.item-title-icon {
							margin-right: 26px;
							margin-top: -1px;

							/deep/.van-icon {
								background-color: #fff;
							}
						}

						.item-title-text {
							margin-top: -1px;
							color: #333;
							font-size: 16px;

							&.title-acitve {
								color: #3377FF;
							}
						}
					}

					.content-item-text {
						margin-left: 36px;
						color: #999;
						font-size: 14px;
						margin-top: 4px;

						span {
							color: #F3B100;
						}

						&.text-active {
							color: #37F;
						}
						
						&.text-active2 {
							color: #FF6969;
						}
					}
				}
			}
			
			.uploader-title{
				margin-left: 36px;
				color: #999;
				font-size: 14px;
				margin-top: 4px;
			}
			
			.uploader {
				display: flex;
				flex-wrap: wrap;
				margin-top: 8px;
				margin-left: 36px;
			
				.uploader-item {
					position: relative;
					margin-right: 12px;
			
					&:last-child {
						margin-right: 0;
					}
			
					.uploader-close {
						position: absolute;
						top: 5px;
						right: 5px;
						width: 16px;
						height: 16px;
					}
			
					.uploader-image {
						width: 75px;
						height: 75px;
						object-fit: cover;
						border-radius: 6px;
					}
				}
			
				.uploader-item-select {
					position: relative;
			
					/deep/ .van-uploader__upload {
						width: 75px;
						height: 75px;
						margin: 0;
						border-radius: 6px;
						background-color: #f2f2f2;
			
						.van-icon {
							font-size: 30px;
							color: #aaa;
						}
					}
				}
			}
			
			.footer{
				width: 100%;
				position: fixed;
				bottom: 20px;
				left: 0;
				padding: 0 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				box-sizing: border-box;
				
				.footer-button-refuse{
					padding: 10px 0;
					flex: 1;
					color: #FF6969;
					text-align: center;
					font-size: 18px;
					border-radius: 6px;
					border: 1px solid #FF6969;
					box-sizing: border-box;
					margin-right: 11px;
					background: #FFF;
				}
				.footer-button-pass{
					padding: 11px 0;
					flex: 1;
					border-radius: 6px;
					background: #37F;
					color: #FFF;
					text-align: center;
					font-size: 18px;
					box-sizing: border-box;
				}
			}
		}
		
		.popup{
			width: 295px;
			height: 251px;
			.popup-content{
				padding: 16px 15px 0;
				box-sizing: border-box;
				.popup-content-title{
					color: #222;
					text-align: center;
					font-size: 17px;
					font-weight: bold;
					line-height: 28px; 
				}
				.popup-content-text{
					color: #777;
					font-size: 16px;
					line-height: 28px; 
					margin-top: 8px;
				}
				.popup-content-textarea{
					width: 100%;
					height: 107px;
					border-radius: 2px;
					border: 1px solid #DDD;
					padding: 8px 10px;
					box-sizing: border-box;
					margin-top: 4px;
					
					textarea{
						width: 100%;
						height: 100%;
						border: 0;
					}
				}
			}
			
			.popup-footer{
				margin-top: 16px;
				text-align: center;
				font-size: 17px;
				line-height: 28px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid #DDD;
				box-sizing: border-box;
				
				.popup-footer-left{
					padding: 8px 0;
					flex: 1;
					color: #AAA;
					border-right: 1px solid #DDD;
					box-sizing: border-box;
				}
				.popup-footer-right{
					padding: 8px 0;
					flex: 1;
					color: #37F;
					box-sizing: border-box;
				}
			}
		}
	}
</style>